/*
 * bouncyトップ画面
*/
const $ = jQuery
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../partials/imagesloaded.pkgd.min'
import '../partials/slick.min'

import '../partials/moovoo/lib/lib.js'
import '../partials/moovoo/common.js'
import '../partials/jq-rotate/jQueryRotate'

import '../partials/auth'
import lazyLoadImage from "../partials/lazyload-image";
import listViewBouncy from '../partials/listview-bouncy'

$(document).ready(function () {
  lazyLoadImage.initModule();
  listViewBouncy.initModule();
});

$(function () {
  $('#bc-top-slide ul').slick({
    centerMode: true,
    centerPadding: '20%',
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    prevArrow: '<div class="SliderPrevArrow"><span></span></div>',
    nextArrow: '<div class="SliderNextArrow"><span></span></div>'
  });
});