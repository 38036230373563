/*
 * listview-bouncy.js
*/

/*jslint         browser : true, continue : true,
  devel  : true, indent  : 2,    maxerr   : 50,
  newcap : true, nomen   : true, plusplus : true,
  regexp : true, sloppy  : true, vars     : false,
  white  : true
*/
/*global $:true, ga:true */

// listViewモジュール。ここに必要な関数を書いてけ
const listViewBouncy = (function () {

  let
    elementMap = {
      $footerImg: null,
      $bcPanelContainer: null,
      $bcCardContainer: null
    },
    stateMap = {
      nowLoadMoreArticle: null,
      nowLoadMoreAuthor: null
    },
    // プライベートメソッド
    loadMoreArticle,
    loadMoreAuthor,
    loadMoreAuthorArticle,
    addMoreArticlePanel,
    addMoreTopArticlePanel,
    addMoreSearchArticle,
    addMoreAuthorPanel,
    addMoreAuthorArticlePanel,
    // パブリックメソッド
    initModule;

  // ------------ プライベートメソッド定義開始 --------------


  loadMoreArticle = function (type) {
    // すでにロード状態だったら何もしない
    if (stateMap.nowLoadMoreArticle !== true) {
      // 読み込み状態に設定
      stateMap.nowLoadMoreArticle = true;

      let nowArticleCount
      if (type === 'writer') {
        nowArticleCount = elementMap.$bcCardContainer.length + 1;
      } else {
        nowArticleCount = elementMap.$bcCardContainer.length;
      }

      elementMap.$footerImg.rotate({
        duration: 1000,
        angle: 0,
        animateTo: 720
      });

      setTimeout(function () {

        // 何ジャンルなのか
        let resource = location.pathname;
        resource = resource.split('/')[resource.split('/').length - 1];

        if (resource === 'bouncy') {
          resource = 'ALL';
        }

        let search_q = ''
        if (location.href.split('?').length > 1) {
          let params = location.href.split('?')[1].split('&')
          for (let i = 0; i < params.length; i++) {
            if (params[i].split('=')[0] === 'q') {
              search_q = '?' + params[i]
            }
          }
        }

        $.ajax({
          type: "get",
          url: "/bouncy/more/" + nowArticleCount + "/" + resource + search_q,
          contentType: 'application/json',
          dataType: "json"
        }).done(function (data) {
          if (data.status === 0) {
            // data取得成功
            if (data.articles.length === 0) {
              // 取得件数0
              // 最後まで読み込んだ
              // $('.mv-mainLeft').append('<div id="mv-listviewLast">'
              //   + '<p>全部読んだみたい<br />ここまで見てくれたあなたに感謝</p>'
              //   + '<img src="/images/movoo_stand.gif" class="mv-listviewLast-img" width="200" alt=""></div> ');
            } else {
              // articlesをパースしてhtmlにappendする
              if (type === 'top' || type === 'writer') {
                $.each(data.articles, function (index, val) {
                  addMoreTopArticlePanel(val);
                });
              } else {
                $.each(data.articles, function (index, val) {
                  addMoreArticlePanel(val);
                });
              }

              // 非読み込み状態に設定
              stateMap.nowLoadMoreArticle = false;
            }
          } else {
            // data取得失敗。通常はここを通らない。
          }
        });

      }, 300);
    }
  };

  loadMoreAuthor = function () {
    // すでにロード状態だったら何もしない
    if (stateMap.nowLoadMoreAuthor !== true) {
      // 読み込み状態に設定
      stateMap.nowLoadMoreAuthor = true;

      const nowAuthorCount = elementMap.$bcCardContainer.length;

      let search_m = '',
        search_r = '',
        search_n = ''
      if (location.href.split('?').length > 1) {
        let params = location.href.split('?')[1].split('&')
        for (let i = 0; i < params.length; i++) {
          if (params[i].split('=')[0] === 'mediaId%5B%5D') {
            search_m += '&' + params[i]
          }
          if (params[i].split('=')[0] === 'roles%5B%5D') {
            search_r += '&' + params[i]
          }
          if (params[i].split('=')[0] === 'name') {
            search_n = '&' + params[i]
          }
        }
      }

      const search_q = '?' + decodeURI(search_m + search_r + search_n)

      setTimeout(function () {

        $.ajax({
          type: "get",
          url: "/author/more/" + nowAuthorCount + search_q,
          contentType: 'application/json',
          dataType: "json"
        }).done(function (data) {
          if (data.status === 0) {
            // data取得成功
            if (data.authors.length === 0) {
              // 取得件数0
              // 最後まで読み込んだ
              // $('.mv-mainLeft').append('<div id="mv-listviewLast">'
              //   + '<p>全部読んだみたい<br />ここまで見てくれたあなたに感謝</p>'
              //   + '<img src="/images/movoo_stand.gif" class="mv-listviewLast-img" width="200" alt=""></div> ');
            } else {
              $.each(data.authors, function (index, val) {
                addMoreAuthorPanel(val);
              });

              // 非読み込み状態に設定
              stateMap.nowLoadMoreAuthor = false;
            }
          } else {
            // data取得失敗。通常はここを通らない。
          }
        });
      }, 300);
    }
  };

  // addMoreArticlePanel
  // 引数: article Json オブジェクト
  // 戻り値: なし
  // 動作:
  //  与えられたarticlejsonオブジェクトの数だけlistviewに記事のパネルを追加
  addMoreArticlePanel = function (article) {

    const
      prHtml = article.shouGenre === 'PR' // 小ジャンルのPRチェック。パネル表示用
        ? '<div class="label">Sponsored</div>'
        : '',
      html = String()
        + '<li>' +
        '<a href="/article/' + article._id + '">' +
        '<div class="bc-card-thumbnail-container">' +
        '<div class="bc-card-thumbnail">' +
        '<img src="https://' + article.resizedMainImg + '"/>' +
        '</div>' +
        '<div class="bc-card-thumbnail-view">' +
        '<div>View</div>' +
        '</div>' +
        '<div class="bc-card-thumbnail-play">' +
        '<span class="bc-icon-video"></span>' +
        '</div> </div> <div class="bc-card-body">' +
        '<div class="bc-card-category">' + article.daiGenre + '</div>' +
        '<h3 class="bc-card-title">' + article.title + '</h3>' +
        '<div class="bc-card-footer">' +
        prHtml +
        '<div class="bc-card-footer-date">' +
        '<span>' + article.pubDate.slice(0, 10).replace(/-/g, ".") + '</span> </div>' +
        '</div> </div> </a> </li>';
    elementMap.$bcPanelContainer.append(html);
    // クロスデザイン謹製の関数当てる
  };

  addMoreTopArticlePanel = function (article) {

    if (!article.leadText) article.leadText = ''
    const
      html = String()
        + '<li class="bc-contents-main__item clearfix">' +
        '            <div class="bc-contents-main__thumb">' +
        '              <a href="/article/' + article._id + '">' +
        '                <img src="https://' + article.resizedMainImg + '" alt="" class="lazyload">' +
        '              </a>' +
        '            </div>' +
        '            <div class="bc-contents-main__text">' +
        '              <div class="bc-card-category">' + article.daiGenre + '</div>' +
        '              <h2><a href="/article/' + article._id + '">' + article.title.replace(/<[^>]+>/, '') + '</a></h2>' +
        '              <p class="bc-contents-main__text-lead">' + article.leadText + '</p>' +
        '              <div class="bc-contents-main__text-bottom clearfix">' +
        '                <p>' + article.pubDate.slice(0, 10).replace(/-/g, ".") + '</p>' +
        ((article.shouGenre === 'PR') ? '<span>Sponsored</span>' : '') +
        '              </div>' +
        '            </div>' +
        '          </li>';
    elementMap.$bcPanelContainer.append(html);
  };

  addMoreSearchArticle = function (article) {

    let tags = ''

    if (article.tags) {
      for (let tag of article.tags) {
        tags += '<span><object><a href="/bouncy/tag/' + tag + '">#' + tag + '</a></object></span>'
      }
    }
    const html = String()
      + '<li class="mv-ListBlock mv-panel">' +
      '<a href="/article/' + article._id + '" class="clearfix mv-ListBlockLink">' +
      '<div class="mv-ListImg">' +
      '<img src="//' + article.resizedMainImg + '" data-img="//' + article.resizedMainImg + '" alt="' + article.title + '" class="aovr"></div>' +
      '<div class="mv-ListText">' +
      '<div class="mv-ListTextInner">' +
      '<div class="bc-card-category">' + article.daiGenre + '</div>' +
      '<h3>' + article.title + '</h3>' +
      '<div id="mv-articleTag">' +
      tags +
      '</div>' +
      '<div id="mv-articleInfo" class="clearfix">' +
      '<div class="mv-author"><i class="icon-user-secret" aria-hidden="true"></i>' + article.mvAuthor + '</div>' +
      '<div class="mv-pubDate"><i class="icon-clock2" aria-hidden="true"></i>' + article.pubDate.slice(0, 10) + '</div>' +
      '</div></div></div></a></li>'
    $('.mv-List ul').append(html);
  };

  addMoreAuthorPanel = function (author) {
    const
      img = author.resizedMainImg
        ? '<img src="//' + author.resizedMainImg + '" alt="' + author.name + '" class="aovr">'
        : '<img src="/images/moovoo/dummy.jpg" alt="' + author.name + '" class="aovr">',
      position = author.position ? author.position : '&nbsp',
      twitter = author.twitterUrl
        ? '<li><a href="https://twitter.com/' + author.twitterUrl + '" target="_blank"><i class="icon-x"></i></a></li>'
        : '',
      facebook = author.facebook
        ? '<li><a href="' + author.facebookUrl + '" target="_blank"><i class="icon-fb"></i></a></li>'
        : '',
      instagram = author.instagramUrl
        ? '<li><a href="' + author.instagramUrl + '" target="_blank"><i class="icon-instagram"></i></a></li>'
        : '',
      youtube = author.youtubeUrl
        ? '<li><a href="' + author.youtubeUrl + '" target="_blank"><i class="icon-youtube"></i></a></li>'
        : '',
      tiktok = author.tiktokUrl
        ? '<li><a href="' + author.tiktokUrl + '" target="_blank"><i class="icon-tiktok"></i></a></li>'
        : '',
      website = author.websiteUrl
        ? '<li><a href="' + author.websiteUrl + '" target="_blank"><i class="icon-sphere"></i></a></li>'
        : '',
      html = String()
        + '<li class="common-authorListBlock">' +
        '<div class="common-authorImg">' + img + '</div>' +
        '<div class="common-authorDate">' +
        '<p>' + position + '</p>' +
        '<h1>' + author.name + '</h1>' +
        '<ul>' +
        twitter +
        facebook +
        instagram +
        youtube +
        tiktok +
        website +
        '</ul></div>' +
        '<div class="common-authorBtn">' +
        '<a href="/author/' + author._id + '">more</a></div></li>';
    elementMap.$bcPanelContainer.append(html);
    // クロスデザイン謹製の関数当てる
  }

  loadMoreAuthorArticle = function () {
    // すでにロード状態だったら何もしない
    if (stateMap.nowLoadMoreArticle !== true) {
      // 読み込み状態に設定
      stateMap.nowLoadMoreArticle = true;

      const nowArticleCount = $('.mv-ListBlock').length;

      let id = location.pathname;
      id = id.split('/')[id.split('/').length - 1];

      setTimeout(function () {

        // ajax通信するよ！！
        $.ajax({
          type: "get",
          url: "/author/" + id + "/more/" + nowArticleCount,
          contentType: 'application/json',
          dataType: "json"
        }).done(function (data) {
          if (data.status === 0) {
            // data取得成功
            if (data.articles.length === 0) {
              // $('.mv-mod-more-btn').hide()
              // 取得件数0
              // 最後まで読み込んだ
            } else {
              $.each(data.articles, function (index, val) {
                addMoreAuthorArticlePanel(val, data.author);
              });

              // 非読み込み状態に設定
              stateMap.nowLoadMoreArticle = false;
            }
          } else {
            // data取得失敗。通常はここを通らない。
          }
        });

      }, 300);
    }
  };

  addMoreAuthorArticlePanel = function (article, author) {

    let tags = ''

    if (article.tags) {
      if (article.mediaId === 'bouncy') {
        for (let tag of article.tags) {
          tags += '<span><object><a href="/bouncy/tag/' + tag + '">#' + tag + '</a></object></span>'
        }
      } else {
        for (let tag of article.tags) {
          tags += '<span><object><a href="/tag/' + tag + '">#' + tag + '</a></object></span>'
        }
      }
    }

    const img = article.resizedMainImg
      ? '<img src="//' + article.resizedMainImg + '" alt="' + article.title + '" class="aovr">'
      : '<img src="/images/moovoo/dummy.jpg" alt="' + article.title + '" class="aovr">',
      mediaId = (article.mediaId === 'moovoo' || !article.mediaId) ? 'Moovoo' : article.mediaId


    const html = String()
      + '<li class="mv-ListBlock mv-panel">' +
      '<a href="/article/' + article._id + '" class="clearfix mv-ListBlockLink">' +
      '<div class="mv-ListImg">' +
      img +
      '</div>' +
      '<div class="mv-ListText">' +
      '<div class="mv-ListTextInner">' +
      '<h3>' + article.title + '</h3>' +
      '<div id="mv-articleTag">' +
      tags +
      '</div>' +
      '<div id="mv-articleInfo" class="clearfix">' +
      '<div class="mv-author"><i class="icon-user-secret" aria-hidden="true"></i>' + author.name + '</div>' +
      '<div class="mv-pubDate"><i class="icon-clock2" aria-hidden="true"></i>' + article.pubDate.slice(0, 10) +
      ' ' + mediaId + '</div>' +
      '</div></div></div></a></li>'
    elementMap.$bcPanelContainer.append(html);
  };

  // ------------ プライベートメソッド定義終了 --------------


  // ------------ パブリックメソッド定義開始 --------------
  initModule = function () {
    // エレメントの登録
    elementMap.$footerImg = $('.mv-footer-booder-img');
    
    $('.bc-scroll-event').on('inview', function (event, isInView) {
      elementMap.$bcPanelContainer = $('.bc-new-arrival-list');
      elementMap.$bcCardContainer = $('.bc-card-body');
      if (isInView) {
        loadMoreArticle();
      }
    });

    $('.bc-scroll-event-top').on('click', function () {
      elementMap.$bcPanelContainer = $('.bc-new-article__list_top');
      elementMap.$bcCardContainer = $('.bc-contents-main__item');
      loadMoreArticle('top');
    });

    $('.bc-scroll-event-writer').on('click', function () {
      elementMap.$bcPanelContainer = $('.bc-new-article__list_writer');
      elementMap.$bcCardContainer = $('.bc-contents-main__item');
      loadMoreArticle('writer');
    });

    $('.bc-scroll-event-authors').on('inview', function (event, isInView) {
      elementMap.$bcPanelContainer = $('.bc-new-arrival-list');
      elementMap.$bcCardContainer = $('.common-authorListBlock');
      if (isInView) {
        loadMoreAuthor();
      }
    });

    $('.bc-scroll-event-author').on('inview', function (event, isInView) {
      elementMap.$bcPanelContainer = $('.bc-new-arrival-list');
      elementMap.$bcCardContainer = $('.mv-ListBlock');
      if (isInView) {
        loadMoreAuthorArticle();
      }
    });

    $('.bc-scroll-event-search').on('inview', function (event, isInView) {
      elementMap.$bcPanelContainer = $('.bc-new-arrival-list');
      elementMap.$bcCardContainer = $('.mv-ListBlock');
      if (isInView) {
        loadMoreArticle();
      }
    });

  };
  // ------------ パブリックメソッド定義終了 --------------

  return {
    initModule: initModule
  };
}());

module.exports = listViewBouncy
